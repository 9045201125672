import React from "react"
import SEO from "../components/seo"
import ContactForm from "../components/Form"
import FAQ from "../components/faq"
import Testimonials from "../components/testimonials"


const SecondPage = () => (
  <>
    <SEO title="Thank you" />
    <div className="hero-wrapper consultation" >
        <div style={{ margin: '0 auto', padding: '0rem 1rem'}}>
        < ContactForm />
        </div>
    </div>
    <FAQ />
    <Testimonials />
  </>
)

export default SecondPage
